import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import {
  mainWhite,
  mainBlue,
  mainOrange,
  screen,
} from "../components/common/variables";
import Layout from "../components/layout";
import SEO from "../components/seo";
import FeaturedProducts from "../components/featured-products";
import LabeledArrow from "../components/labeled-arrow";
import AnniversaryBadge from "../images/svg/thermic-anniv.svg";

const Wrapper = styled.div`
  position: relative;
  z-index: 3;
  padding-top: 74px;
  padding-bottom: 110px;
  @media ${screen.xsmall} {
    padding-top: 73px;
    padding-bottom: 170px;
  }
  @media ${screen.small} {
    padding-top: 76px;
  }
  @media ${screen.large} {
    padding-top: 80px;
  }
  @media ${screen.xlarge} {
    padding-bottom: 200px;
  }

  .home-banner {
    background-image: ${(props) =>
      props.bannerImg && `url(${props.bannerImg})`};
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    padding: 180px 0;
    position: relative;
    @media ${screen.xsmall} {
      padding: 220px 0;
    }
    @media ${screen.small} {
      padding: 230px 0;
      background-size: auto;
    }
    @media ${screen.medium} {
      padding: 300px 0;
    }
    @media ${screen.large} {
      padding: 320px 0;
    }
    @media ${screen.xlarge} {
      padding: 360px 0;
    }

    &__title {
      color: ${mainWhite};
      text-align: center;
      font-size: 1.5rem;
      font-weight: 300;
      max-width: 300px;
      margin: 0 auto;
      position: relative;
      z-index: 2;
      @media ${screen.xsmall} {
        font-size: 2rem;
        max-width: 400px;
        margin: 0 auto;
      }
      @media ${screen.small} {
        font-size: 2.3rem;
        max-width: inherit;
        margin: 0;
      }
      @media ${screen.medium} {
        font-size: 2.5rem;
      }
    }

    &::after {
      content: "";
      background: rgba(0, 35, 94, 0.6);
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      @media ${screen.small} {
        background: rgba(0, 35, 94, 0.3);
      }
    }
  }

  .home-intro {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 80px;
    padding-left: 35px;
    padding-right: 35px;
    @media ${screen.xsmall} {
      padding-bottom: 150px;
      padding-top: 80px;
      padding-left: 70px;
      padding-right: 70px;
    }
    @media ${screen.small} {
      flex-direction: row;
      padding-top: 80px;
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.medium} {
      padding-top: 60px;
      padding-bottom: 60px;
      padding-left: 160px;
      padding-right: 160px;
    }
    @media ${screen.large} {
      padding-top: 110px;
    }
    @media ${screen.xlarge} {
      padding-bottom: 80px;
      padding-left: 280px;
      padding-right: 280px;
    }

    &__heading {
      color: ${mainOrange};
      font-weight: 800;
      text-transform: uppercase;
      white-space: pre-wrap;
      font-size: 2rem;
      margin-bottom: 24px;
      @media ${screen.xsmall} {
        font-size: 3.2rem;
        margin-bottom: 54px;
      }
      @media ${screen.small} {
        margin-bottom: 0;
      }

      @media ${screen.xlarge} {
        font-size: 3.7rem;
      }

      .badge {
        width: 150px;
        margin-top: 20px;
        @media ${screen.xsmall} {
          width: 180px;
        }
      }
    }

    &__description {
      color: ${mainBlue};
      max-width: 600px;
      width: 100%;
      @media ${screen.xsmall} {
        margin-left: 0;
      }
      @media ${screen.small} {
        margin-left: 100px;
      }
      @media ${screen.xlarge} {
        margin-left: 0;
      }

      h2 {
        font-weight: 800;
        font-size: 1.6rem;
        margin-bottom: 5px;
        @media ${screen.xsmall} {
          font-size: 2.1rem;
        }
        @media ${screen.small} {
          margin-bottom: 20px;
        }
        @media ${screen.medium} {
          font-size: 2.2rem;
        }
        @media ${screen.xlarge} {
          font-size: 2.5rem;
          margin-bottom: 30px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      p {
        font-weight: 300;
        font-size: 1.17rem;
        margin-bottom: 22px;
        @media ${screen.xsmall} {
          font-size: 1.3rem;
        }
        @media ${screen.medium} {
          font-size: 1.4rem;
        }
        @media ${screen.xlarge} {
          font-size: 1.5rem;
          margin-bottom: 30px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__link {
      margin-top: 30px;
      @media ${screen.xsmall} {
        margin-left: 0;
        margin-top: 40px;
      }
      @media ${screen.small} {
        margin-left: 100px;
        margin-top: 40px;
      }
      @media ${screen.xlarge} {
        margin-left: 0;
      }

      @media ${screen.xlarge} {
        margin-top: 50px;
      }
    }
  }

  .home-cta {
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 0;
    padding-bottom: 70px;
    @media ${screen.xsmall} {
      padding-top: 170px;
      padding-left: 70px;
      padding-right: 70px;
      padding-bottom: 150px;
    }
    @media ${screen.small} {
      padding-top: 150px;
      padding-left: 280px;
      padding-right: 280px;
    }
    @media ${screen.xlarge} {
      padding-top: 180px;
      padding-bottom: 180px;
    }

    &__heading {
      max-width: 780px;
      margin: 0 auto;

      p {
        color: ${mainBlue};
        font-size: 1.7rem;
        font-weight: 300;
        line-height: 1.25;
        @media ${screen.xsmall} {
          font-size: 2.4rem;
        }
        @media ${screen.small} {
          font-size: 2.55rem;
        }
        @media ${screen.medium} {
          font-size: 2.9rem;
        }
        @media ${screen.xlarge} {
          font-size: 3.1rem;
        }
      }

      strong {
        font-weight: 800;
      }
    }

    &__link {
      max-width: 780px;
      margin: 22px auto 0 auto;

      svg {
      }
    }
  }

  .related-blogs {
    @media ${screen.xsmall} {
      padding-left: 70px;
      padding-right: 70px;
    }
    @media ${screen.small} {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.medium} {
      padding-left: 160px;
      padding-right: 160px;
    }
    @media ${screen.xlarge} {
      padding-left: 280px;
      padding-right: 280px;
    }
  }
`;

const IndexPage = ({ data }) => {
  const {
    title_tag,
    meta_description,
    banner_image,
    banner_text,
    introduction_heading,
    introduction_description,
    cta_heading,
  } = data.main.data;

  return (
    <Layout>
      <SEO title={title_tag} description={meta_description} />
      <Wrapper bannerImg={banner_image.url}>
        <section className="home-banner">
          <h1 className="home-banner__title">{banner_text.text}</h1>
        </section>

        <section className="home-intro">
          <div className="home-intro__heading">
            {introduction_heading}

            <div className="badge">
              <AnniversaryBadge />
            </div>
          </div>

          <div>
            <div
              className="home-intro__description"
              dangerouslySetInnerHTML={{
                __html: introduction_description.html,
              }}
            />
            <LabeledArrow
              className="home-intro__link"
              label={`MORE ABOUT US`}
              url={`/about`}
            />
          </div>
        </section>

        <FeaturedProducts heading={`Shop Our Products`} />

        <section className="home-cta">
          <div
            className="home-cta__heading"
            dangerouslySetInnerHTML={{ __html: cta_heading.html }}
          />
          <LabeledArrow
            className="home-cta__link"
            label={`GOT QUESTIONS? GET IN TOUCH WITH OUR TEAM`}
            question="true"
            url={`/contact`}
            long="true"
          />
        </section>
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;

export const dataQuery = graphql`
  {
    main: prismicHomePage {
      data {
        introduction_heading
        title_tag
        meta_description
        banner_text {
          text
        }
        banner_image {
          alt
          url
        }
        introduction_description {
          html
        }
        cta_heading {
          html
        }
      }
    }
  }
`;
